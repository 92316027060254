import React, { useState, useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Navigate, useParams ,Link} from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import * as dataJSON from "../../Data/CreateAccount.json";
import Meta from "../Meta";
import axios from "axios";
import "./CreateAccount.scss";
import {
  FormikStepper,
  FormikStep
} from "formik-stepper";
import "formik-stepper/dist/style.css";
export const CreateAccount = (props) => {
  const { lang } = useParams();
  const [data, setData] = useState(dataJSON.default[lang]);
  const [meta, setMeta] = useState({
    title: dataJSON.default[lang].meta.title,
    description: dataJSON.default[lang].meta.description,
    canonical: dataJSON.default[lang].meta.canonical,
    image: dataJSON.default[lang].meta.image,
    meta: {
      charset: "utf-8",
      name: {
        keywords: dataJSON.default[lang].meta.keywords,
      },
    },
  });
  const [statusData, setStatusData] = useState(false);
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    setData(dataJSON.default[lang]);
    setMeta({
      title: dataJSON.default[lang].meta.title,
      description: dataJSON.default[lang].meta.description,
      canonical: dataJSON.default[lang].meta.canonical,
      keywords: dataJSON.default[lang].meta.keywords,
      image: dataJSON.default[lang].meta.image,
    });
  }, [lang]);

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        //console.log(data)
        setCountryData(data);
      });
  }, []);

  countryData.sort(function compare(a, b) {
    if (a.translations.fra.common < b.translations.fra.common) return -1;
    if (a.translations.fra.common > b.translations.fra.common) return 1;
    return 0;
  });
  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );

  const validationSchema = Yup.object().shape({
    lastName: Yup.string()
      .required(data.error.errorForm)
      .min(1, data.error.firstNameMin)
      .max(20, data.error.firstNameMax),
    firstName: Yup.string()
      .required(data.error.errorForm)
      .min(1, data.error.firstNameMin)
      .max(20, data.error.firstNameMax),
    //phone_number: Yup.string().matches(phoneRegex, data.error.phoneInvalid).required(data.error.phoneNumber),
    // dateofbirdth: Yup.string().required(data.error.dateofbirdth),
    //company_name: Yup.string().required(data.error.errorForm),
    country: Yup.string().required(data.error.errorForm),
    address: Yup.string().required(data.error.errorForm),
    town: Yup.string().required(data.error.errorForm),
    // effectif: Yup.string().required(data.error.errorForm),
    role: Yup.string().required(data.error.errorForm),
    postal_code: Yup.string().required(data.error.errorForm),
    phone_number: Yup.string().required(data.error.errorForm),
    civility: Yup.string().required(data.error.errorForm),
    email: Yup.string()
      .required(data.error.emailRequired)
      .email(data.error.emailInvalid),
    confirmEmail: Yup.string()
      .required(data.error.confirmRequiredMail)
      .oneOf([Yup.ref("email"), null], data.error.confirmOneOfMail),
   


    password: Yup.string()
      .required(data.error.passwordRequired)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])(?=.{8,32})/,
        data.error.passwordMin
      ),
    confirmPassword: Yup.string()
      .required(data.error.confirmRequired)
      .oneOf([Yup.ref("password"), null], data.error.confirmOneOf),
      acceptTerms: Yup.bool().oneOf([true], data.error.checkbox),
    

  });

  const initialValues = {
    lastName: "",
    firstName: "",
    role: "",
    phone_number: "",
    company_name: "",
    effectif: "",
    address: "",
    town: "",
    postal_code: "",
    country: "",
    email: "",
    password: "",
    confirmPassword: "",
    confirmEmail: "",
    num_cabinet:"",
    civility:"",
    acceptTerms: false
  };
  
   //const hostName="http://localhost/Back-end/addUser";
   const hostName = "/Back-end/addUser";
  const handleSubmit = (userData) => {
    axios.post(hostName, userData)
      .then((response) => {
        console.log(response);
        if (response.data.status === false) {
          setStatusData(false);
          document.querySelector(".error").innerHTML = data.error.messageError;
          document.querySelector(".error").classList.add("reponseError");
        } else {
          setStatusData(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setStatusData(false);
      });
     
     
     
  };
  if (statusData === true) {
    return <Navigate to={`/${lang}/confirm`} />;
  }

  if (sessionStorage.getItem("userData")) {
    return <Navigate to={`/${lang}/HomeAcademy`} />;
  }
  return (
    <div>
      <Meta meta={meta} />
      <Breadcrumb title={data.breadcrumb} />
      <div className="container formCreate">
        <div className="row">
          <div className="col-md-8 offset-md-2 pt-3">
           
            <FormikStepper
            
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmit(values)}
              withStepperLine
              props            
              nextButton={{
                label: data.form.nextButton,
                style: {
                  background: "#15acf2",
                  padding: "5px 25px",
                  //textTransform: "uppercase",
                  width: "200px",
                  border:"none",
                  margin:"10px 2px",
                  color:"#fff",
                  borderRadius:"0%"
                  
                },
              }}
              prevButton={{
                label: data.form.prevButton,
                style: {
                  background: "#DC3535",
                  padding: "5px 25px",
                  //textTransform: "uppercase",
                  width: "200px",
                  border:"none",
                  margin:"10px  2px",
                  color:"#fff",
                  borderRadius:"0%"
                
                 
                },
              }}
              submitButton={{
                label: data.form.ButtonText1,
                style: {
                  background: "#15acf2",
                  padding: "5px 25px",
                  //textTransform: "uppercase",
                  width: "200px",
                  border:"none",
                  color:"#fff",
                  margin:"10px 2px",
                  borderRadius:"0%"
                  
                },
              }}
              
            >
               
              {/*  First Step */}
            
          
              <FormikStep
                label={data.form.h3ContactInformation} /// The text label of Step
                labelColor="#15acf2" /// css-colors => #fff
                circleColor="#15acf2" /// css-colors => #fff
              >
                 <div className="mb-3 row">
                  <label htmlFor="civility" className="col-sm-3 col-form-label">{data.form.LabelCivility}</label>
                
                <div className="col-sm-8">
                <div className="form-check form-check-inline">
                  <Field type="radio" name="civility" value="mr" className="form-check-input"/>
                
                 <label className="form-check-label" htmlFor="inlineRadio1">   {data.form.civilityText1}</label>
                </div>
                
                <div className="form-check form-check-inline">
                  <Field type="radio" name="civility" value="mrs" className="form-check-input"/>
                
                 <label className="form-check-label" htmlFor="inlineRadio2">   {data.form.civilityText2}</label>
                </div>
                  
                   
                  <ErrorMessage
                    name="civility"
                    // component="small"
                    // className="text-danger"
                    render={(msg) => (
                      <div className="text-danger">
                        <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                        {msg}
                      </div>
                    )}
                  />
                 </div>
                </div>
                {/* <h4 className="text-center mt-2">{data.form.h3ContactInformation}</h4> */}
                <div className="mb-3 row">
                  <label htmlFor="lastName" className="col-sm-3 col-form-label">{data.form.LabelLastName}</label>
                
                <div className="col-sm-8">
                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    className=" form-control fontSize"
                  />
                  <ErrorMessage
                    name="lastName"
                    // component="small"
                    // className="text-danger"
                    render={(msg) => (
                      <div className="text-danger">
                        <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                        {msg}
                      </div>
                    )}
                  />
                 </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="firstName" className="col-sm-3 col-form-label">{data.form.labelFirstName}</label>
                  <div className="col-sm-8">
                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    className=" form-control fontSize"
                  />
                  <ErrorMessage
                    name="firstName"
                    // component="small"
                    // className="text-danger"
                    render={(msg) => (
                      <div className="text-danger">
                        <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                        {msg}
                      </div>
                    )}
                  />
                  </div>
                </div>
              
                <div className="mb-3 row">
                  <label htmlFor="phone_number" className="col-sm-3 col-form-label">
                    {data.form.LabelPhoneNumber}
                    
                  </label>
                 
                  <div className="col-sm-8">
                  <Field
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    className="fontSize form-control"
                  />
                  <ErrorMessage
                    name="phone_number"
                    render={(msg) => (
                      <div className="text-danger">
                        <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                        {msg}
                      </div>
                    )}
                  />
                </div>
                </div>
                   <div className="row mb-3">
                    <label htmlFor="address" className="col-sm-3 col-form-label">{data.form.LabelAddress}</label>
                 
                  <div className="col-sm-8">
                  <Field
                    type="text"
                    id="address"
                    name="address"
                    className=" fontSize form-control"
                  />
                  <ErrorMessage
                    name="address"
                    // component="small"
                    // className="text-danger"
                    render={(msg) => (
                      <div className="text-danger">
                        <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                        {msg}
                      </div>
                    )}
                  />
                  </div>
               </div>
              
                <div className="mb-3 row">
                  <label htmlFor="town" className="col-sm-3 col-form-label">{data.form.LabelTown}</label>
                 
                  <div className="col-sm-8">

                  <Field
                    type="text"
                    id="town"
                    name="town"
                    className=" fontSize form-control"
                  />
                  <ErrorMessage
                    name="town"
                    // component="small"
                    // className="text-danger"
                    render={(msg) => (
                      <div className="text-danger">
                        <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                        {msg}
                      </div>
                    )}
                  />
                  </div>
                </div>
                
                <div className="mb-3 row">
                  <label htmlFor="postal_code" className="col-sm-3 col-form-label">
                    {data.form.LabelCodePostal}
                  </label>
                 
                  <div className="col-sm-8">
                  <Field
                    type="text"
                    id="postal_code"
                    name="postal_code"
                    className=" fontSize form-control"
                  />
                  <ErrorMessage
                    name="postal_code"
                    // component="small"
                    // className="text-danger"
                    render={(msg) => (
                      <div className="text-danger">
                        <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                        {msg}
                      </div>
                    )}
                  />
                </div>
                </div>
               
                <div className="row mb-3">
                  <label htmlFor="country" className="col-sm-3 col-form-label">{data.form.LabelCountry}</label>
              
                  <div className="col-sm-8">
                  <Field as="select" name="country" className=" form-select fontSize">
                    <option disabled value="">
                      {data.form.LabelSelectCountry}
                    </option>
                    {countryData.map((country, index) => {
                      return lang === "fr" ? (
                        <option
                          key={index}
                          value={country.translations.fra.common}
                        >
                          {country.translations.fra.common}
                        </option>
                      ) : (
                        <option
                          key={country.name.common}
                          value={country.name.common}
                        >
                          {country.name.common}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    name="country"
                    // component="small"
                    // className="text-danger"
                    render={(msg) => (
                      <div className="text-danger">
                        <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                        {msg}
                      </div>
                    )}
                  />
                  </div>
                </div>
                <p className="textObli">{data.form.textObliga}</p>
              </FormikStep>
             
              {/* secondStep */}
              <FormikStep
                label={data.form.h3ProfessionalActivity} /// The text label of Step
                labelColor="#15acf2" /// css-colors => #fff
                circleColor="#15acf2" /// css-colors => #fff
              >
                {/* <h4 className="text-center mb-4">{data.form.h3ProfessionalActivity}</h4> */}
                <div className="row mb-3">
                  <label htmlFor="role" className="col-sm-3 col-form-label">{data.form.LabelRole}</label>
                  <div className="col-sm-8">
                  <Field as="select" name="role" className="form-select fontSize ">
                    <option disabled value="">
                      {data.form.LabelSelect}
                    </option>
                    {data.form.role.map((role, index) => (
                      <option key={index} value={role}>
                        {role}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="role"
                    render={(msg) => (
                      <div className="text-danger">
                        <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                        {msg}
                      </div>
                    )}/>
                    </div>
                    </div>
                  <div className="row mb-3">
                    <label htmlFor="company_name" className="col-sm-3 col-form-label">
                      {data.form.LabelCompanyName}<span className="red">*</span>
                    </label>
                    <div className="col-sm-8">
                    <Field
                      type="text"
                      id="company_name"
                      name="company_name"
                      className=" form-control fontSize"
                    />
                  </div>
                  </div>
                  <div className="row mb-3">
                  <label htmlFor="effectif" className="col-sm-3 col-form-label">
                  {data.form.LabelEffectif}<span className="red">*</span>
                    </label>
                    <div className="col-sm-8">
                  <Field
                    type="number"
                    id="effectif"
                    name="effectif"
                    className=" form-control fontSize"
                  />
                  </div>
                  
                  </div>
                  <div className="row mb-3">
                  <label htmlFor="num_cabinet" className="col-sm-3 col-form-label">
                  {data.form.LabelNumCabinet}<span className="red">*</span>
                    </label>
                    <div className="col-sm-8">
                  <Field
                    type="text"
                    id="num_cabinet"
                    name="num_cabinet"
                    className=" form-control fontSize"
                  />
                  </div>
                  </div>
                  <p className="textObli">{data.form.textObliga}</p>
                  <p className="textStep2">{data.form.textStep2}</p>
              </FormikStep> 
                 {/* last Step */}   
              <FormikStep label={data.form.h3Connection} 
              circleColor="#15acf2"
              labelColor="#15acf2"
              >
                 <p className="error"></p>
                {/* <h4 className="text-center">{data.form.h3Connection}</h4> */}
                <div className="row mb-3">
                  <label htmlFor="email" className="col-sm-4 col-form-label">{data.form.LabelEmail}</label>
                  <div className="col-sm-8">
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="form-control fontSize"
                  />
                  <ErrorMessage
                    name="email"
                    render={(msg) => (
                      <div className="text-danger">
                        <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                        {msg}
                      </div>
                    )}
                  />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="email" className="col-sm-4 col-form-label">{data.form.labelConfirmMail}</label>
                  <div className="col-sm-8">
                  <Field
                    type="email"
                    id="confirmEmail"
                    name="confirmEmail"
                    className="form-control fontSize"
                  />
                  <ErrorMessage
                    name="confirmEmail"
                    // component="small"
                    // className="text-danger"
                    render={(msg) => (
                      <div className="text-danger">
                        <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                        {msg}
                      </div>
                    )}
                  />
                  </div>
                </div>
                <div className="row mb-3">
                <label htmlFor="password" className="col-sm-4 col-form-label">{data.form.LabelPassword}</label>
                <div className="col-sm-8">
                <Field
                  type="password"
                  id="password"
                  name="password"
                  className="form-control fontSize"
                />
                <ErrorMessage
                  name="password"
                  render={(msg) => (
                    <div className="text-danger">
                      <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                      {msg}
                    </div>
                  )}
                />
                </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="confirmPassword" className="col-sm-4 col-form-label">
                    {data.form.labelConfirm}
                  </label>
                  <div className="col-sm-8">
                  <Field
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    className="form-control fontSize"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    render={(msg) => (
                      <div className="text-danger">
                        <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                        {msg}
                      </div>
                    )}
                  />
                  </div>
                </div>
                <div className="form-group form-check mb-5 mt-5">
                <Field
                    name="acceptTerms"
                    type="checkbox"
                    className="form-check-input"
                    style={{display:"block"}}
                />
                <span
                   
                    //className="form-check-label"
                >
                   {data.form.checkbox}
                </span>
                <ErrorMessage
                    name="acceptTerms"
                    component="small"
                    className="text-danger d-block"
                />
            </div>
                <p className="textObli">{data.form.textObliga}</p>
                <p className="textConfir">{data.form.textConfir}</p>


              </FormikStep>  
              
            </FormikStepper>
            <p className="textConnexion">{data.form.pConnexion}  
             <Link to={`/${lang}/${data.form.SLUG}`} 
            
          >{data.form.TextSLUG}
          </Link></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;




