import React from "react";
import UserAccount from "./Pages/UserAccount/UserAccount"
import { Routes, Route, Navigate} from "react-router-dom";
import Login from "./Pages/Login/Login";
import Confirm from "./Pages/Confirm/Confirm";
import MailRest from "./Pages/MailResetPassword/MailRest";
import Profile from "./Pages/Profile/Profile";
import ScientificStudies from "./Pages/Scientific_study/Study";
import Protocols from "./Pages/Protocoles/Protocoles";
import ElearningPageDetail from "./Pages/E-learning/ElearningPageDetail"
import ElearningDetail from "./Pages/E-learning/ElearningDetail"

import HomeAccount from "./Pages/BlogAccount/BlogAccount";
import TechnicalSupports from "./Pages/TechnicalSupports/TechnicalSupport";
import Elearning from "./Pages/E-learning/Elearning";
import Notifications from "./Pages/Notifications/Notifications";
import ClinicalsCsae from "./Pages/clincalsCase/ClinicalsCsae";
import MyFavoris from "./Pages/MyFavoris/MyFavoris"
import MyDevice from "./Pages/MyDevice/MyDevice";

import HomePage from "./Pages/HomePage/HomePage"
import MediaLibrary from "./Pages/MediaLibrary/MediaLibrary";

const RoutesRouter = (props) => {
    return (
        <Routes>
            <Route path="/:lang/"
                element={<HomePage />}
            />
            <Route path="/:lang/create-account/"
                element={<UserAccount />}

            />
            <Route path="/:lang/connexion"
                element={<Login />}
            />
            <Route path="/:lang/confirm/"
                element={<Confirm />}
            />

            <Route path="/:lang/recupMail/"
                element={<MailRest />}
            />


            <Route path="/:lang/HomeAcademy/"

                element={<HomeAccount />} />

            <Route path="/:lang/ScientificStudies/"
                element={<ScientificStudies />}

            />

            <Route path="/:lang/Protocols/"
                element={<Protocols />}
            />
            <Route path="/:lang/ClinicalCase/"

                element={<ClinicalsCsae />}

            />
            <Route path="/:lang/Toolbox/"

                element={<TechnicalSupports />}

            />
            <Route path="/:lang/Profile/"

                element={<Profile />}

            />
            <Route path="/:lang/MyDevice/"

                element={<MyDevice />}

            />
            <Route path="/:lang/MyFavourites/"

                element={<MyFavoris />}

            />
            <Route path="/:lang/E-learning/"
                element={<Elearning />}

            />
            <Route path="/:lang/E-learning/:slug"
                element={<ElearningPageDetail />}

            />
            <Route path="/:lang/E-learning/:slug/:id"
                element={<ElearningDetail />}

            />

            <Route path="/:lang/Suitcase_Marketing/"
                element={<MediaLibrary />}

            />
            <Route path="/:lang/Notifications/"
                element={<Notifications />} />

            <Route path="/" element={<Navigate replace to="/fr/" />} />        

            {/* <Redirect exact from="/" to="/fr/" /> */}

        </Routes>
    );
};

export default RoutesRouter;
