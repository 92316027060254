import React,{useState, useEffect} from 'react';
import { withRouter,Redirect, Link} from "react-router-dom";
import './AddDevice.scss';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { BiMessageAltError } from "react-icons/bi";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon
} from 'mdb-react-ui-kit'


export const AddDevice = ({date, numSerial, spanRevendeur, 
  text1,title ,labelRevendeur, labelFile, 
  ButtonText1, spanFile, pFile, textMail, textOblig, buttonFile, inputFile, errorNubmerSerie,numMin, numMax, msg, msgE, textError, textError2}) => {
 
    const [status, setStatus] = useState(false);
    // const[auth,setAuth]=useState([]);
    const [basicModal, setBasicModal] = useState(false);
   
  
    const toggleShow = () => {setBasicModal(!basicModal)
      
    };
    console.log(basicModal)
  
   let auth=[];
    auth=JSON.parse(sessionStorage.getItem('userData'))
    const validationSchema = Yup.object().shape({
    serial_num: Yup.string().matches(/^[0-9]/,errorNubmerSerie)
                        .min(4, numMin)
                       .max(5, numMax) 
                        .required(text1),
      date_purchase: Yup.string()
      .required(text1),
      name_distributer: Yup.string()
          .required(text1)
      });
    
    
   
      const initialValues = {  
        serial_num: "",
        name_distributer: "",
        date_purchase:"",
        myFile:"",
        firstName:auth["first_name"],
        lastName:auth["last_name"],
        user_id:auth["user_id"],
        email:auth["email"],
        id_sellsy:auth["id_sellsy"]

      };
     const hostName="/Back-end/addDevice";
     //const hostName="http://localhost/Back-end/addDevice"
      const handleSubmit = (dataDevice) => {
      
           axios.post(hostName,dataDevice)
           
          .then((response)=>{
            console.log(response.data)
            //console.log(response)
           
              window.location.reload();


           
          //else{
          //   document.getElementById('modal').style.display = 'block'
          //   document.getElementById('modalAdd').style.display = 'none'
          
            
          //  }
            
          }).catch(error=>{
            console.log(error)
          })
        
      };

      // const uploadFiles=()=>{
      //   document.querySelector(".hiddenFileInput").click();
      // }
       
      return (
    
        <>

    
            <MDBIcon onClick={toggleShow} fas icon="plus-circle"  style={{ color: '#15acf2', fontSize: '3rem', margin:"5px" }}/>
            <div className='modalAdd'>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
        <MDBModalDialog  style={{margin:"200px auto auto",maxWidth:1000}}>
          <MDBModalContent style={{borderRadius:"25px", border:"1px solid #15acf2",paddingLeft:"10px", fontWeight:"bold"}}>
            <MDBModalHeader style={{border:"none", paddingBottom:"0px"}}>
              <MDBModalTitle style={{textTransform:"uppercase" , fontWeight:"bold"}}>{title}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>  <p className='error'></p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(deviceData)=>{handleSubmit(
                    // file: deviceData.file, 
                    // type: deviceData.file.type,
                    // size: `${deviceData.file.size} bytes`,
                    // serial_num:deviceData.serial_num,
                    // name_distributer:deviceData.name_distributer,
                    // date_purchase:deviceData.date_purchase,
                    // user_id:deviceData.user_id,
                    // email:deviceData.email,
                    // id_sellsy:deviceData.id_sellsy
                    deviceData

                ); 
                  
              }}
              >
                {({ 
                  handleChange,
                  handleBlur,
                  values,
                  setFieldValue,
                  handleReset
                   }) => (
                  <Form className='formDevice'>

                  <div className="form-group mb-4">
                      <label htmlFor=" serial_num" className='me-2'>
                       {numSerial}
                      </label>
                      <span className='font'>MDP 19237/ </span>
                      <Field
                        type="text"
                        id="serial_num"
                        name="serial_num"
                        placeholder="_ _ _ _ _  "
                      />
                      <ErrorMessage
                        name="serial_num"
                        component="small"
                        className="text-danger"
                      />
                    </div>
                   
                    <div className="form-group mb-4">
                      <label htmlFor="name_distributer">
                     {labelRevendeur}
                      </label>
                       <span className='span'>{spanRevendeur} </span>
                       <span>: </span>
                      <Field
                        type="text"
                        id="name_distributer"
                        name="name_distributer"
                        
                      />
                      <ErrorMessage
                        name="name_distributer"
                        component="small"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group mb-5">
                      <label htmlFor="date_purchase">
                     {date} &nbsp; </label>
                      <Field
                        type="date"
                        id="date_purchase"
                        name="date_purchase"
                        placeholder={date}
                        
                      />
                      <ErrorMessage
                        name="date_purchase"
                        component="small"
                        className="text-danger"
                      />
                    </div>
                    {/* <div className="form-group mb-5">
                      <label htmlFor="file">
                     {labelFile}
                      </label>
                      <span className='span'>{spanFile}</span>
                      <span>:</span>
                      <p className='span'>{pFile}</p> */}
                    
                    {/* <Field type="file" id="myFile" name="myFile"
                    className="hiddenFileInput"
                    style={{display:"none"}} 
                    onChange={(event) => {
                      const fileChosen = document.getElementById('file-chosen');

                      fileChosen.textContent = event.currentTarget.files[0].name;
              }}
                    /> */}
                        {/* <label htmlFor="file" className="ButtonFile" onClick={uploadFiles}>{buttonFile}</label> */}
                  {/* <span id="file-chosen">{inputFile}</span>  */}
                    {/* </div> */}
                    
                    <MDBModalFooter className="d-flex justify-content-between align-items-start" style={{border:"none", paddingTop:"0px"}}>
              <div>
              <span className='span'>{textMail}</span>
              <div className='textOblig'>{textOblig}</div>
              </div>
             
             <button type="submit" style={{background:"#15acf2", border:"none", width:"150px", color:"#fff", padding:"10px", borderRadius:"10px", fontWeight:"bold"}}>{ButtonText1}</button>
                    
            
            </MDBModalFooter>
            <p className='msg'></p>
                  </Form>
                )}
              </Formik>
              </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      </div>
      {/* <div className='modal' id="modal">
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
        <MDBModalDialog  style={{margin:"200px auto auto",maxWidth:1000}}>
          <MDBModalContent  style={{borderRadius:"25px", border:"1px solid #15acf2",paddingLeft:"10px"}}>
            <MDBModalHeader style={{border:"none", paddingBottom:"0px"}}>
              
              <MDBBtn className='btn-close' color='none'  onClick={() => {
                  setBasicModal(!basicModal);
                  window.location.reload()
                }}></MDBBtn>
            </MDBModalHeader >
            <MDBModalBody  className="formDevice">
              <BiMessageAltError/>
              <div className='deviceCenter'>
              <p >{textError}</p>
              <p >{textError2}</p>
              </div>
              
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      </div> */}
      
          </>
    
      )
    };

export default AddDevice;
